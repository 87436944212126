function Terms() {
  return (
    <div id="terms">
      <h1>Terms of Service</h1>
      <p>
        These terms govern your access to and use of PeerReviewBot.com, as well
        as all content and PeerReviewBot products and services available at or
        through these websites (collectively, “Services”), which are owned by
        Alta Consulting LLC. Our Services are offered subject to your
        acceptance, without modification, of all of the terms and conditions
        contained herein and all other operating rules, policies (including,
        without limitation, PeerReviewBot’s Privacy Policy), and procedures that
        may be published from time to time by Alta Consulting LLC (collectively,
        the “Agreement”). You agree that we may automatically upgrade our
        Services, and the Agreement will apply to any upgrades. Please read the
        Agreement carefully before accessing or using our Services. By accessing
        or using any part of our Services, you agree to become bound by the
        Agreement. If you do not agree to all the terms of the Agreement, then
        you may not access or use our Services.
      </p>
      <h2>Your Account</h2>
      <p>
        Where use of our Services requires an account, you agree to provide us
        with complete and accurate information when you register for an account.
        You will be solely responsible and liable for any activity that occurs
        under your username. You are responsible for keeping your account
        information up-to-date and for keeping your password secure. You are
        responsible for maintaining the security of your account and any
        Service-related website, store, or other content, and you are fully
        responsible for all activities that occur under your account and any
        other actions taken in connection with our Services. You shall not share
        or misuse your access credentials. You must immediately notify us of any
        unauthorized uses of your account, store, or website, or of any other
        breach of security. We will not be liable for any acts or omissions by
        you, including any damages of any kind incurred as a result of such acts
        or omissions. When you create a PeerReviewBot.com account, we consider
        that to be an inquiry about our products and services, which means that
        we may contact you to share more details about what we have to offer.
        Don't worry -- if you aren't interested in learning more, you can opt
        out of the marketing communication, whether it's an email, phone call,
        or text message.
      </p>
      <h2>Responsibility of Visitors and Users</h2>
      <p>
        We have not reviewed, and cannot review, all of the content (such as,
        but not limited to, text, photo, video, audio, code, computer software,
        items for sale, or other materials) posted to our Services by users or
        anyone else (“Content”) and are not responsible for any use or effects
        of such Content. So, for example: We do not endorse any Content or
        represent that Content is accurate, useful, or non-harmful. Content
        could be offensive, indecent, or objectionable; include technical
        inaccuracies, typographical mistakes, or other errors; or violate or
        infringe the privacy, publicity rights, intellectual property rights
        (see our Copyright Infringement and DMCA Policy section to submit
        copyright complaints), or other proprietary rights of third parties. We
        disclaim any responsibility for any harm resulting from anyone’s use,
        purchase, or downloading of Content. If you access or use any Content,
        you are responsible for taking precautions as necessary to protect
        yourself and your computer systems from viruses, worms, Trojan horses,
        and other harmful or destructive content. We are not a party to, and
        will have no responsibility or liability for, any communications,
        transactions, interactions, or disputes between you and the provider of
        any Content. Please note that additional third party terms and
        conditions may apply to the downloading, copying, purchase, or use of
        Content.
      </p>
      <h2>Fees, Payment, and Renewal</h2>
      <h3>Fees for Paid Services</h3>
      <p>
        Some of our Services are offered for a fee, such as PeerReviewBot.com
        plans. By using a Paid Service, you agree to pay the specified fees.
        Depending on the Paid Service, there may be a one-time fee, recurring
        fees, or revenue-based fee. For recurring fees, we’ll bill or charge you
        for in regular automatically-renewing intervals (such as monthly,
        annually, or biennially), on a pre-pay basis until you cancel, which you
        can do at any time by contacting the relevant support team.
      </p>
      <h3>Payment</h3>
      <p>
        If your payment fails or if Paid Services are otherwise not paid for or
        paid for on time, we may immediately cancel or revoke your access to the
        Paid Services. If you contact your bank or credit card company to
        decline or reverse the charge of fees for Paid Services, we may revoke
        your access to our Services in general.
      </p>
      <h3>Automatic Renewal</h3>
      <p>
        To ensure uninterrupted service, recurring Paid Services are
        automatically renewed. This means that unless you cancel a Paid Service
        before the end of the applicable subscription period, it will
        automatically renew, and you authorize us to invoice you or use any
        payment mechanism we have on record for you to collect the
        then-applicable subscription fee (as well as any Taxes). By default,
        your Paid Services will be renewed for the same interval of time as your
        original subscription period. For example, if you purchase a
        PeerReviewBot.com annual plan, you will be charged each year for the
        following 12-month period. We may charge your account up to 7 days
        before the end of the subscription period. The date for the automatic
        renewal is determined automatically based on the date of the original
        purchase and cannot be changed.
      </p>
      <h3>Cancelling Automatic Renewal</h3>
      <p>
        Please contact support at any time if you would like to disable
        automatic renewal.
      </p>
      <h3>Fee Changes</h3>
      <p>
        We may change our fees at any time, or start charging fees for Services
        that were previously free. When applicable, we may give you advance
        notice of the fee changes. If you don’t agree with the fee changes, you
        must cancel your Paid Service.
      </p>
      <h3>Refunds</h3>
      <p>
        While you may cancel a Paid Service at any time, refunds are issued in
        our sole discretion, unless otherwise required by applicable law.
      </p>
      <h2>General Representation and Warranty</h2>
      <p>
        You represent and warrant that your use of our Services:
        <ul>
          <li>Will be in strict accordance with these Terms;</li>
          <li>
            Will comply with all applicable laws and regulations (including,
            without limitation, all applicable laws regarding online conduct and
            acceptable content, privacy, data protection, and the transmission
            of technical da taexported from the United States or the country in
            which you reside);
          </li>
          <li>
            Will not use the Services for any unlawful purposes, to publish
            illegal content, or in furtherance of illegal activities;
          </li>
          <li>
            Will not infringe or misappropriate the intellectual property rights
            of any third party;
          </li>
          <li>
            Will not overburden PeerReviewBot’s systems, as determined by us in
            our sole discretion;
          </li>
          <li>Will not disclose sensitive personal information of others;</li>
          <li>
            Will not interfere with, disrupt, or attack any service or network;
          </li>
          <li>
            Will not be used to create, distribute, or enable material that is -
            or that facilitates or operates in conjunction with - malware,
            spyware, adware, or other malicious programs or code.
          </li>
        </ul>
      </p>
      <h2>Copyright Infringement and DMCA Policy</h2>
      <p>
        As we ask others to respect our intellectual property rights, we respect
        the intellectual property rights of others. If you believe that material
        located on or associated with an PeerReviewBot product or service
        violates your copyright, please notify us immediately. We will respond
        to all such notices, including as required or appropriate by removing
        the infringing material or disabling all links to the infringing
        material.
      </p>
      <h2>Intellectual Property</h2>
      <p>
        The Agreement does not transfer from Alta Consulting LLC to you any Alta
        Consulting LLC or third party intellectual property, and all right,
        title, and interest in and to such property will remain (as between the
        parties) solely with PeerReviewBot. PeerReviewBot, PeerReviewBot.com,
        the PeerReviewBot.com logo, and all other trademarks, service marks,
        graphics, and logos used in connection with PeerReviewBot.com or our
        Services, are trademarks or registered trademarks of Alta Consulting LLC
        or PeerReviewBot’s licensors. Other trademarks, service marks, graphics,
        and logos used in connection with our Services may be the trademarks of
        other third parties. Your use of our Services grants you no right or
        license to reproduce or otherwise use any Alta Consulting LLC or third
        party trademarks.
      </p>
      <h2>Changes</h2>
      <p>
        We are constantly updating our Services and that means sometimes we have
        to change the legal terms under which our Services are offered. These
        Terms may only be modified by a written amendment signed by an
        authorized executive of Alta Consulting LLC, or by the posting by Alta
        Consulting LLC of a revised version. If we make changes that are
        material, we will let you know by by sending you an email or other
        communication before the changes take effect. The notice will designate
        a reasonable period of time after which the new terms will take effect.
        If you disagree with our changes, then you should stop using our
        Services within the designated notice period, or once the changes become
        effective. Your continued use of our Services will be subject to the new
        terms. However, any dispute that arose before the changes shall be
        governed by the Terms (including the binding individual arbitration
        clause) that were in place when the dispute arose.
      </p>
      <h2>Termination</h2>
      <p>
        We may terminate your access to all or any part of our Services at any
        time, with or without cause, with or without notice, effective
        immediately. We have the right (though not the obligation) to, in our
        sole discretion, terminate or deny access to and use of any of our
        Services to any individual or entity for any reason. We will have no
        obligation to provide a refund of any amounts previously paid. If you
        wish to terminate the Agreement or your PeerReviewBot.com account, you
        may simply discontinue using our Services, or, if you are using a paid
        service, you may cancel at any time, subject to the Fees, Payment, and
        Renewal section in these Terms. All provisions of the Agreement which by
        their nature should survive termination shall survive termination,
        including, without limitation, ownership provisions, warranty
        disclaimers, indemnity, and limitations of liability.
      </p>
      <h2>Disclaimer of Warranties</h2>
      <p>
        Our Services are provided “as is.” Alta Consulting LLC and its suppliers
        and licensors hereby disclaim all warranties of any kind, express or
        implied, including, without limitation, the warranties of
        merchantability, fitness for a particular purpose and non-infringement.
        Neither PeerReviewBot, nor its suppliers and licensors, makes any
        warranty that our Services will be error free or that access thereto
        will be continuous or uninterrupted. You understand that you download
        from, or otherwise obtain content or services through, our Services at
        your own discretion and risk.
      </p>
      <h2>Limitation of Liability</h2>
      <p>
        In no event will Alta Consulting LLC, or its suppliers or licensors, be
        liable with respect to any subject matter of the Agreement under any
        contract, negligence, strict liability or other legal or equitable
        theory for: (i) any special, incidental or consequential damages; (ii)
        the cost of procurement for substitute products or services; (iii) for
        interruption of use or loss or corruption of data; or (iv) for any
        amounts that exceed $50 or the fees paid by you to Alta Consulting LLC
        under the Agreement during the twelve (12) month period prior to the
        cause of action, whichever is greater. Alta Consulting LLC shall have no
        liability for any failure or delay due to matters beyond their
        reasonable control. The foregoing shall not apply to the extent
        prohibited by applicable law.
      </p>
      <h2>Indemnification</h2>
      <p>
        You agree to indemnify and hold harmless PeerReviewBot, its contractors,
        and its licensors, and their respective directors, officers, employees,
        and agents from and against any and all losses, liabilities, demands,
        damages, costs, claims, and expenses, including attorneys’ fees, arising
        out of or related to your use of our Services, including but not limited
        to your violation of the Agreement.
      </p>
      <h2>Miscellaneous</h2>
      <p>
        The Agreement constitutes the entire agreement between Alta Consulting
        LLC and you concerning the subject matter hereof. If any part of the
        Agreement is held invalid or unenforceable, that part will be construed
        to reflect the parties’ original intent, and the remaining portions will
        remain in full force and effect. A waiver by either party of any term or
        condition of the Agreement or any breach thereof, in any one instance,
        will not waive such term or condition or any subsequent breach thereof.
        You may assign your rights under the Agreement to any party that
        consents to, and agrees to be bound by, its terms and conditions; Alta
        Consulting LLC may assign its rights under the Agreement without
        condition. The Agreement will be binding upon and will inure to the
        benefit of the parties, their successors and permitted assigns.
      </p>
    </div>
  );
}

export default Terms;
