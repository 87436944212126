function Failure() {
  return (
    <div id="installation-failure">
      <h1>Whoops</h1>
      <p>
        Seems like we had trouble installing PeerReviewBot. Check to make sure
        you don't already have it installed and try again!
      </p>
    </div>
  );
}

export default Failure;
