import { Routes, Route } from "react-router-dom";

import logo from "./logos/Peer_Review_Bot_Slackbot_App_FaviconTransparent.png";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";

import Home from "./pages/home";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import Success from "./pages/success";
import Failure from "./pages/failure";

function App() {
  return (
    <div className="App">
      <Navbar bg="light" variant="light" expand="lg" sticky="top">
        <Container>
          <Navbar.Brand href="/">
            <img
              alt=""
              src={logo}
              width="26"
              height="30"
              className="d-inline-block align-top"
            />{" "}
            Peer Review Bot
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav>
              <Nav.Link href="/#features">Features</Nav.Link>
              <Nav.Link href="/#support">Support</Nav.Link>
              <Nav.Link href="/privacy">Privacy</Nav.Link>
              <Nav.Link href="/terms">Terms</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid="md">
        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/success" element={<Success />} />
          <Route path="/failure" element={<Failure />} />
        </Routes>
      </Container>
    </div>
  );
}

export default App;
