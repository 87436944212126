function Home() {
  return (
    <div>
      <div id="welcome">
        <h1>Welcome!</h1>
        <p>
          PeerReviewBot is a bot for Slack that streamlines peer feedback, which
          is crucial for fostering a strong culture of team development. The bot
          simplifies the process of collecting and sharing feedback by
          integrating it directly into Slack, the tool commonly used for team
          collaboration. This eliminates the need for manually typing up
          feedback at the end of the year, enabling users to leave feedback as
          it happens and access it at any time in the future.
        </p>
      </div>
      <hr></hr>
      <div id="features">
        <h1>Features</h1>
        <p>Configure PeerReviewBot with slash commands!</p>
        <ul>
          <li>
            <code>/review [@someone]</code>: to leave a review of your peer
          </li>
          <li>
            <code>/readreviews [@someone][last n days]</code>: to read your own
            reviews or the reviews of someone else (with their permission)
          </li>
          <li>
            <code>/addmanager [@someone]</code>: to authorize your manager to
            read your peer reviews
          </li>
          <li>
            <code>/removemanager [@someone]</code>: to de-authorize your manager
            to read your peer reviews
          </li>
        </ul>
        <p>
          Drop us a line by sending the bot a direct message in the messages
          tab!
        </p>
        <a href="https://slack.com/oauth/v2/authorize?client_id=4564031685540.4574215966177&scope=chat:write,commands,im:history&user_scope=">
          <img
            alt="Add to Slack"
            height="40"
            width="139"
            src="https://platform.slack-edge.com/img/add_to_slack.png"
            srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
          />
        </a>
      </div>
      <hr />
      <div id="support">
        <h1>Support</h1>
        <p>
          Email us at <code>team@peerreviewbot.com</code> for support!
        </p>
      </div>
    </div>
  );
}

export default Home;
