function Success() {
  return (
    <div id="installation-success">
      <h1>Success</h1>
      <p>
        Congratulations! You successfully installed PeerReviewBot to your Slack
        workspace. Share feedback!
      </p>
    </div>
  );
}

export default Success;
